import { gql } from ':src/__generated__/gql';

export const sessionsQuery = gql(/* GraphQL */ `
  query SessionsQuery(
    $limit: Int!
    $offset: Int!
    $sort: Sort
    $beforeDate: Date
    $afterDate: Date
    $sessionTypeId: Int
    $sessionDispositionIds: [Int]
    $organizationId: Int
    $userId: Int
    $noShowOnly: Boolean
    $programId: String
  ) {
    sessions(
      limit: $limit
      offset: $offset
      sort: $sort
      beforeDate: $beforeDate
      afterDate: $afterDate
      sessionTypeId: $sessionTypeId
      sessionDispositionIds: $sessionDispositionIds
      userId: $userId
      organizationId: $organizationId
      noShowOnly: $noShowOnly
      programId: $programId
    ) {
      result {
        id
        scheduledAt
        sessionType {
          displayName
        }
        duration
        createdBy
        supervisor {
          id
          firstName
          lastName
          fullName
          mediumAvatar
        }
        sessionAttendees {
          user {
            id
            firstName
            lastName
            fullName
            mediumAvatar
            organizationUser {
              id
              organizationProgram {
                id
                name
              }
            }
          }
          attended
          billable
          partialAttendanceMinutes
        }
        sessionDispositionId
        organizationSession {
          id
          organization {
            id
            name
            organizationUsers {
              id
              user {
                id
                fullName
                mediumAvatar
              }
            }
          }
        }
        sessionNotes {
          id
          question
          answer
        }
        cancelledAt
        cancelledBy {
          id
          fullName
        }
        unavailableParty {
          id
          fullName
        }
        sessionCancellationReason {
          id
          name
        }
        cancellationReason
        recurrenceRule {
          id
        }
      }
      total
    }
  }
`);

export const organizationByIdQuery = gql(/* GraphQL */ `
  query OrganizationByIdQuery($id: Int!) {
    organizationById(id: $id) {
      id
      name
      canRemoveSupervisees
      organizationUsers {
        id
        user {
          fullName
          id
        }
      }
    }
  }
`);

export const upcomingSessionsQuery = gql(/* GraphQL */ `
  query UpcomingSessionsQuery($limit: Int!, $organizationId: Int, $programId: String) {
    upcomingSessions(limit: $limit, organizationId: $organizationId, programId: $programId) {
      result {
        id
        scheduledAt
        sessionType {
          displayName
        }
        duration
        supervisor {
          id
          fullName
          mediumAvatar
        }
        sessionDispositionId
        sessionAttendees {
          user {
            id
            fullName
            mediumAvatar
          }
          attended
          partialAttendanceMinutes
        }
        sessionNotes {
          id
          question
          answer
        }
      }
    }
  }
`);

export const organizationSummaryByIdQuery = gql(/* GraphQL */ `
  query OrganizationSummaryByIdQuery($organizationId: Int!, $programId: String) {
    organizationSummaryById(organizationId: $organizationId, programId: $programId) {
      userCount
      pendingUserCount
      inactiveUserCount
      totalMinutes
      organization {
        id
        usesInvoice
      }
    }
  }
`);

export const userByIdQuery = gql(/* GraphQL */ `
  query UserByIdQuery($id: Int!, $deletedForOrganizationId: Int) {
    userById(id: $id) {
      id
      smallAvatar
      mediumAvatar
      firstName
      lastName
      avatarUrl
      fullName
      usersLicenses {
        license {
          name
        }
        usState {
          abbreviation
        }
      }
      organizationSuperviseeOnboardingRequest {
        usState {
          id
          name
          abbreviation
        }
        licenseCategory {
          id
          name
          displayName
          abbreviation
        }
      }
      organizationUser(deletedForOrganizationId: $deletedForOrganizationId) {
        id
        deletedAt
        scheduledForRemovalOn
      }
    }
  }
`);

export const sessionsSummaryByIdQuery = gql(/* GraphQL */ `
  query SessionsSummaryById($userId: Int) {
    superviseeSessionsSummaryById(userId: $userId) {
      totalMinutes
      individualMinutes
      dyadMinutes
      groupMinutes
    }
  }
`);
