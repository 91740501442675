import { SESSIONS_PER_PAGE } from ':src/globalConstants';
import useQueryParams from ':src/hooks/useQueryParams';
import {
  EmptyOrLoadingState,
  MoAvatar,
  MoPersistentTooltip,
  MoScrollContainer,
} from '@motivo/guanyin/src/components';
import {
  formatDateTimeWithOrdinalAndYear,
  getDurationTextFromMinutes,
} from '@motivo/guanyin/src/utils/dateUtils';
import { getAvatarSizeBasedOnAttendeeLength } from '@motivo/guanyin/src/utils/helperUtils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  AvatarGroup,
  Box,
  Collapse,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { SessionsQueryQuery } from ':src/__generated__/graphql';
import { AttendanceStatusEnum, SessionDateFilter } from '../../utils';
import { AttendanceDisplay, AttendanceDisplayProps } from './AttendanceDisplayChip';
import { getSuperviseeNames } from './LinkedSuperviseeNames';

const StyledHeadTableCell = styled(TableCell)`
  font-weight: bold;
  border-bottom: none;
  padding: ${({ theme }) => theme.spacing(2)};
  min-width: 50px;
`;

const StyledTableCell = styled(TableCell)`
  padding: ${({ theme }) => theme.spacing(2)};
  min-width: 50px;
  vertical-align: top;
`;

type SessionRowProps = {
  session: NonNullable<SessionsQueryQuery['sessions']>['result'][number];
  shouldDisplaySessionNotes: boolean;
  userId?: number;
  isMissedTab: boolean;
  isCancelledTab: boolean;
};

type SessionsTableProps = {
  userId?: number;
  hasSelectedSupervisor: boolean;
  userQueryLoading: boolean;
  sessionData: NonNullable<SessionsQueryQuery['sessions']>;
  sessionLoading: boolean;
  shouldDisplaySessionNotes: boolean;
};

type BillableData = {
  billable?: boolean | null;
  attended?: boolean | null;
  partialAttendanceMinutes?: number | null;
};

const mapBillableAttendedDataToEnum = (attendee: BillableData) => {
  const { billable, attended, partialAttendanceMinutes } = attendee;

  if (billable === null && attended === null && partialAttendanceMinutes === null) {
    return null;
  }

  if (partialAttendanceMinutes && billable && attended) {
    return AttendanceStatusEnum.PARTIAL_SESSION;
  }
  if (billable && attended) {
    return AttendanceStatusEnum.ENTIRE_SESSION;
  }
  if (billable) {
    return AttendanceStatusEnum.NO_SHOW;
  }

  return AttendanceStatusEnum.RESCHEDULED;
};

function SessionRow({
  session,
  userId,
  isMissedTab,
  isCancelledTab,
  shouldDisplaySessionNotes,
}: SessionRowProps) {
  const theme = useTheme();
  const {
    scheduledAt,
    sessionType,
    duration,
    supervisor,
    sessionAttendees,
    sessionNotes,
    cancelledAt,
    cancelledBy,
    cancellationReason,
    sessionCancellationReason,
    unavailableParty,
  } = session;
  const [open, setOpen] = useState(false);
  const [expandAttendees, setExpandAttendees] = useState(false);
  const scheduledAtDateTime = DateTime.fromISO(scheduledAt);
  const selectedAttendee =
    userId && sessionAttendees.find((attendee) => attendee.user.id === userId);
  const selectedAttendeeStatus =
    selectedAttendee && mapBillableAttendedDataToEnum(selectedAttendee);
  const hasSelectedSupervisor = session.supervisor.id === userId;
  const shouldDisplayAttendance =
    (selectedAttendee && !hasSelectedSupervisor) || isMissedTab || isCancelledTab;
  const attendees = isMissedTab
    ? sessionAttendees.filter((attendee) => attendee.billable && !attendee.attended)
    : sessionAttendees;
  const size = getAvatarSizeBasedOnAttendeeLength(attendees.length);
  let attendanceDisplayProps: AttendanceDisplayProps | null = null;

  if (shouldDisplayAttendance) {
    if (isMissedTab) {
      attendanceDisplayProps = {
        attendanceType: AttendanceStatusEnum.NO_SHOW,
        duration: session.duration,
      };
    } else if (isCancelledTab) {
      attendanceDisplayProps = null;
    } else if (selectedAttendeeStatus) {
      attendanceDisplayProps = {
        attendanceType: selectedAttendeeStatus,
        partialAttendanceMinutes: selectedAttendee.partialAttendanceMinutes,
        duration: session.duration,
      };
    }
  }

  return (
    <>
      <TableRow sx={{ height: '112px' }}>
        <StyledTableCell>
          <AvatarGroup
            max={4}
            spacing="small"
            sx={{
              display: 'inline-flex',
            }}
          >
            {attendees.map((attendee) => (
              <MoAvatar user={attendee.user} key={attendee.user.id} size={size} />
            ))}
          </AvatarGroup>
        </StyledTableCell>
        <StyledTableCell sx={{ minWidth: '100px' }}>
          <div>
            <strong>{formatDateTimeWithOrdinalAndYear(scheduledAtDateTime)}</strong>
          </div>
          <div>{DateTime.fromISO(scheduledAt).toLocaleString(DateTime.TIME_SIMPLE)}</div>
        </StyledTableCell>
        <StyledTableCell
          size="small"
          sx={{ fontWeight: 'bold', maxWidth: '250px', minWidth: '100px' }}
        >
          {sessionType.displayName === 'Individual'
            ? 'Session '
            : `${sessionType.displayName} session `}
          with{' '}
          {getSuperviseeNames(
            attendees,
            expandAttendees,
            setExpandAttendees,
            theme.palette.secondary.main,
          )}
        </StyledTableCell>
        <StyledTableCell>{getDurationTextFromMinutes(duration)}</StyledTableCell>
        <StyledTableCell>{sessionType.displayName}</StyledTableCell>
        {!hasSelectedSupervisor && <StyledTableCell>{supervisor.fullName}</StyledTableCell>}
        {attendanceDisplayProps && (
          <StyledTableCell>
            <AttendanceDisplay {...attendanceDisplayProps} />
          </StyledTableCell>
        )}
        {isCancelledTab && (
          <>
            <StyledTableCell sx={{ minWidth: '100px' }}>
              {cancelledAt && DateTime.fromISO(cancelledAt).toLocaleString(DateTime.DATE_SHORT)}
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: '100px' }}>
              <Typography>
                {cancelledBy?.fullName}
                {unavailableParty ? ` (unavailable: ${unavailableParty.fullName})` : ''}
              </Typography>
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: '100px' }}>
              <Typography whiteSpace="pre-line">
                {sessionCancellationReason?.name &&
                  sessionCancellationReason?.name !== 'Other' &&
                  `${sessionCancellationReason.name}`}
                {cancellationReason ? `${cancellationReason}` : ''}
              </Typography>
            </StyledTableCell>
          </>
        )}
        {shouldDisplaySessionNotes && (
          <StyledTableCell>
            {sessionNotes.length > 0 && (
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </StyledTableCell>
        )}
      </TableRow>
      <TableRow>
        {sessionNotes.length > 0 && (
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }}
            colSpan={999}
          >
            <MoScrollContainer height="200px">
              <Collapse in={open} timeout="auto" unmountOnExit>
                {sessionNotes.filter(Boolean).map((notes) => (
                  <Box key={notes.id}>
                    <Typography my={2}>
                      <strong>{notes.question}</strong>
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      maxWidth="900px"
                      whiteSpace="pre-line"
                    >
                      {notes.answer}
                    </Typography>
                  </Box>
                ))}
              </Collapse>
            </MoScrollContainer>
          </StyledTableCell>
        )}
      </TableRow>
    </>
  );
}

export default function SessionsTable({
  userId,
  hasSelectedSupervisor,
  userQueryLoading,
  sessionData,
  sessionLoading,
  shouldDisplaySessionNotes,
}: SessionsTableProps) {
  let sessions = sessionData.result;
  const { total } = sessionData;
  const { queryParams, pushUpdateQueryParams } = useQueryParams();
  const { page: pageParam, sessionDateFilter } = queryParams;
  const isMissedTab = sessionDateFilter === SessionDateFilter.MISSED;
  const isCancelledTab = sessionDateFilter === SessionDateFilter.CANCELLED;
  const isTodayTab = sessionDateFilter === SessionDateFilter.TODAY_AND_PAST;
  const page = Number(pageParam) || 1;
  const totalPages = Math.ceil(total / SESSIONS_PER_PAGE);
  const shouldDisplayAttendance = (userId && !hasSelectedSupervisor && isTodayTab) || isMissedTab;

  if (isCancelledTab) {
    sessions = sessions.filter((session) => !session.recurrenceRule);
  }

  if (sessionLoading || userQueryLoading) {
    return (
      <Box py={4}>
        <EmptyOrLoadingState loading />
      </Box>
    );
  }
  return (
    <Box px={4}>
      <TableContainer sx={{ mt: 4 }}>
        {sessions?.length === 0 ? (
          <EmptyOrLoadingState title="No sessions found." />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <StyledHeadTableCell></StyledHeadTableCell>
                <StyledHeadTableCell>Date</StyledHeadTableCell>
                <StyledHeadTableCell>Title</StyledHeadTableCell>
                <StyledHeadTableCell>Duration</StyledHeadTableCell>
                <StyledHeadTableCell>Type</StyledHeadTableCell>
                {!hasSelectedSupervisor && <StyledHeadTableCell>Supervisor</StyledHeadTableCell>}
                {shouldDisplayAttendance && <StyledHeadTableCell>Attendance</StyledHeadTableCell>}
                {isCancelledTab && (
                  <>
                    <StyledHeadTableCell>Cancelled on</StyledHeadTableCell>
                    <StyledHeadTableCell>Cancelled by</StyledHeadTableCell>
                    <StyledHeadTableCell>Reason</StyledHeadTableCell>
                  </>
                )}
                {shouldDisplaySessionNotes && (
                  <StyledHeadTableCell
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Notes
                    <MoPersistentTooltip
                      titleContent={
                        <Typography padding={1}>
                          If attendance has not yet been marked, then notes won’t show up in this
                          column
                        </Typography>
                      }
                      placement="top-start"
                      arrow
                    >
                      {({ toggleTooltip }) => (
                        <IconButton onClick={toggleTooltip} aria-label="Close">
                          <InfoOutlinedIcon />
                        </IconButton>
                      )}
                    </MoPersistentTooltip>
                  </StyledHeadTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {sessions.map((session) => (
                <SessionRow
                  key={session.id}
                  session={session}
                  userId={userId && Number(userId)}
                  isMissedTab={isMissedTab}
                  isCancelledTab={isCancelledTab}
                  shouldDisplaySessionNotes={shouldDisplaySessionNotes}
                />
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {totalPages > 1 && (
        <Box py={2} display="flex" justifyContent="center">
          <Pagination
            page={page}
            count={totalPages}
            onChange={(_, page) => {
              window.scrollTo(0, 0);
              pushUpdateQueryParams({ page: page.toString() });
            }}
          />
        </Box>
      )}
    </Box>
  );
}
